section[data-section='articulos'] {
  .item-art {
    padding: 20px;
    background: white;
    box-shadow: 0 1px 2px grey;
    margin-bottom: 25px;
    min-height: 390px;

    figure {
      text-align: center;

      img {
        display: block;
        margin: 0 auto;
        max-height: 208px;
      }
    }

    .title {
      text-transform: uppercase;
      font-family: $lato;
      margin: 10px 0;
    }
    p{
      font-family: $lato;
      font-size: 1.4rem;
      text-align: justify;
    }
  }
}