header {
  //position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  background: white;
  box-shadow: 0 1px 1px rgba(128, 128, 128, 0.42);
  display: block;

  @include mobile {
    background: $gris;
    box-shadow: none;
  }

  .header-logo {
    @include mobile {
      position: relative;

      .open-menu {
        position: absolute;
        right: 7%;
        top: 50%;
        transform: translateY(-50%);
        color: black;

        @include mobile {
          color: white;
        }
      }
    }

    .logo {
      display: flex;
      align-items: center;
      height: 80px;

      @include mobile {
      }

      img {
        height: 70%;
        margin-left: 5px;
      }
    }
  }

  nav {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all .3s ease-in;

    @include mobile {
      justify-content: center;
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: -200%;
      background: white;
      z-index: 999;

      &.active {
        top: 0;
      }
    }

    ul {
      width: 100%;
      display: flex;
      justify-items: flex-end;

      @include mobile {
        width: 100%;
        flex-direction: column;
      }


      li {
        flex: 1;
        text-align: center;
        padding: 10px;
        position: relative;


        @include mobile {
          padding: 0;
        }

        &.visible-xs {
          text-align: center;

          img {
            display: block;
            margin: 0 auto;
            max-height: 70px;
          }
        }

        a {
          min-height: 56px;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-family: $lato;
          color: $gris;
          font-size: 1.2rem;
          transition: all .3s ease-in;
          padding: 10px 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mobile {
            //border:1px solid red;
            display: flex;
            padding: 10px;
            width: 100%;
            height: 100%;
          }

          &:hover {
            background: rgba(128, 128, 128, 0.36);
            text-decoration: none;
            color: black;
          }
        }


      }
    }

    .has-submenu {
      &:hover {
        .submenu {
          display: flex;
        }
      }

      .submenu {
        display: none;
        width: 100%;
        position: absolute;
        left: 0;
        background: white;
        z-index: 1300;
        flex-direction: column;
        li{
          padding: 0;
        }
      }
    }
  }


}

@include mobile {
  .nav-home {
    background: transparent;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1500;
    height: 60px;

    &.active {
      background: rgba(black, .5);
    }

    .logo {
      display: flex;
      align-items: center;
      height: 40px;

      @include mobile {
        justify-content: start;
        height: 50px !important;
        margin-top: 5px;
      }

      img {
        height: 70%;
        margin-left: 5px;
      }
    }
  }

}
