section[data-section='home'] {
  @include mobile {
    background: $gris;
  }

  .solicita-boton {
    padding: 20px 0;
    display: block;

    .btn {
      width: 30%;
      padding: 15px;

      @include mobile {
        width: 80%;
        font-size: 11px;
        margin-bottom: 10px;
      }
    }
  }

  .slider {
    //position: relative;
  }


  .people-container {
    text-align: center;
    transform: translateY(-54%);
    //border: 1px solid red;
    //width: 60%;
    margin: 0 auto;
    height: 330px;
    z-index: 100 !important;

    @include mobile {
      height: 60px;
      //border:1px solid red;
      transform: unset;
      position: absolute;
      left: 0;
      top: 30%;
    }

    .titles {
      //border: 1px solid white;
      display: block;
      padding-bottom: 25px;

      h2, h3 {
        text-transform: uppercase;
        color: white;

      }

      h2 {
        @include mobile {
          font-size: 5vw;
        }
      }

      h3 {
        font-weight: 300;

        @include mobile {
          font-size: 4.5vw;
        }
      }
    }


  }

  .people-container-mobile {
    //background: $gris;
    .slick-list {
      //background: red;
      height: 490px;
      margin-bottom: 0;
      padding-bottom: 25px;
    }

    &.slick-slider {
      margin-bottom: -1px !important;
      padding-bottom: 15px;
      border: none;
    }
  }


}