section[data-section='previa'] {
  height: 100vh;
  background-size: cover;
  background-position: center center;

  .text {
    position: fixed;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    @include mobile {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    h2, h3 {
      text-shadow: 1px 1px 2px black;
      text-transform: uppercase;
      color: white;
      font-family: $lato;
    }

    h2 {
      font-size: 38px;
      font-weight: 700;
    }

    h3 {
      letter-spacing: 1px;
      margin-top: 4px;
      font-weight: 600;
    }

    a {
      border: none;
      background: white;
      box-shadow: 1px 1px 2px black;
      margin-top: 10px;
      font-weight: 700;
      font-size: 17px;
      text-transform: uppercase;
    }
  }


  .contacto {
    position: fixed;
    right: -150%;
    top: 57%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    padding: 1.5%;
    transition: all .9s;

    @include mobile {
      padding: 6%;
    }


    &.active {

      right: 0;

      @include mobile {
        background: rgba(0, 0, 0, 0.9);
      }
    }

    .contacto-link-close {
      position: absolute;
      left: -3%;
      top: -2%;
      padding: 5px 10px;
      color: black;
      transition: all .3s ease-in-out;
      background: white;
      border-radius: 50px;
      @include mobile {
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover {
        background: black;
        color: white;
      }
    }

    form {
      label {
        font-family: $lato;
        color: white;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      input, textarea {
        width: 100%;
      }

      textarea {
        height: 150px;
      }

      button {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: $lato;
      }
    }
  }
}


