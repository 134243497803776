.slider-people {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    display: block;
  }
}

.item-person {
  text-align: center;
  width: 250px;
  margin: 0 25px;
  flex-basis: 250px;
  overflow: hidden;

  @include mobile {
    width: auto;
  }

  figure {
    overflow: hidden;
    background: grey;
    box-shadow: 0 0 2px grey;

    figcaption {
      padding: 10px;

      h3, h4 {
        color: white;
      }

      h3 {
        font-size: 17px;
      }

      h4 {
        margin-top: 4px;
        font-size: 13px;
      }
    }
  }
}

html {
  overflow-x: hidden;

  body {
    overflow-x: hidden;

    ul, li, h1, h2, h3, h4, h5 {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}

.redes {
  position: fixed;
  left: 0;
  background: black;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  height: 20%;
  justify-content: space-between;

  @include mobile {
    transform: unset;
    top: unset;
    bottom: 1%;
    z-index: 777;
  }

  a {
    font-size: 20px;
    color: white;
    transition: all .3s ease-in;

    &:hover {
      color: yellow;
    }
  }
}

figure {
  line-height: 0;
}

.boton {
  background: $gris;
  font-family: $lato;
  border: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}

h1, h2, h3, h4 {
  font-family: $lato;
  font-weight: 600;
}

.title {
  font-family: $lato;
  text-transform: uppercase;
  color: $gris;
  font-weight: 700;
  margin-bottom: 5px;
}

.section-page {
  display: block;
  padding-top: 35px;
  padding-bottom: 35px;
}

.title-section {
  text-transform: uppercase;
  font-family: $lato;
  font-size: 2.2rem;
  letter-spacing: 2px;
  color: $gris;
  margin-bottom: 15px;
  font-weight: 600;

  &.margin-top {
    margin-top: 10px;
  }
}

//.slick-slider {
//  display: table !important;
//  table-layout: fixed;
//  width: 100% !important;
//}

.solicita {
  background: white;
  padding: 5% 0;

  .titles {
    text-align: center;
    margin-bottom: 25px;

    h1, h2, h3, h4 {
      font-family: $lato;
    }

    h2 {
      text-transform: uppercase;
      font-size: 3vw;
      letter-spacing: 2px;
      font-weight: 800;
      margin-bottom: 8px;
      color: $gris;
      @include mobile {
        font-size: 3vw * 2;
      }
    }

    h3 {
      font-weight: 600;
      color: $yellow;
      letter-spacing: 2px;
    }
  }


  .items {
    figure {
      text-align: center;

      img {
        margin: 0 auto;
        display: block;
        height: 250px;
      }
    }

    p {
      color: $gris;
      text-align: center;
      padding: 0 8%;
      font-family: $lato;
    }
  }
}
