section[data-section='contacto'] {

  display: block;

  form {
    .form-contol {
      border-radius: 0 !important;
    }

    textarea {
      height: 128px;
    }
  }

  h3 {
    font-family: $lato;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .vias-container {

    @include mobile {
      margin-top: 20px;
    }

    ul.vias {
      li {
        font-size: 1.7rem;
      }
    }
  }

  .mapa {
    margin-top: 10px;
    padding: 10px;
    background: white;
    box-shadow: 0 0 2px grey;
  }
}