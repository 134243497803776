section[data-section='pagar'] {
  .promos {
    margin-top: 10px;

    h3, h4 {
      text-transform: uppercase;
      color: $gris;
      font-size: 1.5rem;
      margin-bottom: 5px;
    }
  }

  .data {
    padding-bottom: 10px;

    li {
      font-family: $lato;

      b {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 800;
      }
    }
  }
}