section[data-section='sobre'] {
  .sobre-text {

    .image {
      background-size: cover;
      background-position: center center;
    }

    .text {
      font-family: $lato;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      flex-direction: column;
      color: $gris;

      .li-space {
        li {
          padding-bottom: 12px;
        }
      }

      h2 {
        margin-bottom: 20px;
        font-weight: 900;
        font-size: 2.4rem;

        span {
          display: block;
          margin-top: 5px;
          text-transform: uppercase;
          font-size: 1.2rem;
        }
      }

      h3 {
        margin-top: 10px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-size: 1.8rem;
      }

      p {
        text-align: justify;
        margin-bottom: 20px;
      }

      > div {
        padding: 10%;
        @include mobile {
          padding: 35px 5%;
        }
      }

      li {
        padding-bottom: 2px;
      }
    }
  }
}