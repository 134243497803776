section[data-section="turnos-presenciales"] {
  figure {
    display: block;
    margin: 60px 0;
  }

  h2 {
    display: block;
    margin: 80px 0;
    font-weight: 400;
    font-family: $lato;
  }

  h2, h3, h4 {
    font-family: $lato;
    color: $gris;
  }

  h3.sub {
    background: $gris;
    color: white;
    padding: 10px 20px 10px 15px;
    display: inline-block;
    margin-bottom: 5px;
    border-radius: 0 10px 0 0;
  }

  p {
    margin: 0;
    font-family: $lato;
    padding: 0;

  }

  .items {
    padding: 60px 0;

    .item {
      text-align: center;

      @include mobile {
        margin-bottom: 20px;
      }

      img {
        margin-bottom: 10px;
      }

      h3 {
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 5px;
        font-size: 30px;
      }

      p {
        color: $gris;
        font-size: 22px;
      }

    }
  }

  .primera-consulta, .control {

    p {
      font-size: 23px;
      font-weight: 400;
    }


    h4 {
      font-size: 35px;
      text-transform: uppercase;
      font-weight: 800;
      margin-top: 10px;
    }
  }

  .team {
    margin-top: 30px;
    background: $gris;
  }
}