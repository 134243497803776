@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap");
.slider-people {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  .slider-people {
    display: block;
  }
}

.item-person {
  text-align: center;
  width: 250px;
  margin: 0 25px;
  flex-basis: 250px;
  overflow: hidden;
}
@media (max-width: 480px) {
  .item-person {
    width: auto;
  }
}
.item-person figure {
  overflow: hidden;
  background: grey;
  box-shadow: 0 0 2px grey;
}
.item-person figure figcaption {
  padding: 10px;
}
.item-person figure figcaption h3, .item-person figure figcaption h4 {
  color: white;
}
.item-person figure figcaption h3 {
  font-size: 17px;
}
.item-person figure figcaption h4 {
  margin-top: 4px;
  font-size: 13px;
}

html {
  overflow-x: hidden;
}
html body {
  overflow-x: hidden;
}
html body ul, html body li, html body h1, html body h2, html body h3, html body h4, html body h5 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.redes {
  position: fixed;
  left: 0;
  background: black;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  height: 20%;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .redes {
    transform: unset;
    top: unset;
    bottom: 1%;
    z-index: 777;
  }
}
.redes a {
  font-size: 20px;
  color: white;
  transition: all 0.3s ease-in;
}
.redes a:hover {
  color: yellow;
}

figure {
  line-height: 0;
}

.boton {
  background: #33546c;
  font-family: "Lato", sans-serif;
  border: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}

h1, h2, h3, h4 {
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.title {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #33546c;
  font-weight: 700;
  margin-bottom: 5px;
}

.section-page {
  display: block;
  padding-top: 35px;
  padding-bottom: 35px;
}

.title-section {
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-size: 2.2rem;
  letter-spacing: 2px;
  color: #33546c;
  margin-bottom: 15px;
  font-weight: 600;
}
.title-section.margin-top {
  margin-top: 10px;
}

.solicita {
  background: white;
  padding: 5% 0;
}
.solicita .titles {
  text-align: center;
  margin-bottom: 25px;
}
.solicita .titles h1, .solicita .titles h2, .solicita .titles h3, .solicita .titles h4 {
  font-family: "Lato", sans-serif;
}
.solicita .titles h2 {
  text-transform: uppercase;
  font-size: 3vw;
  letter-spacing: 2px;
  font-weight: 800;
  margin-bottom: 8px;
  color: #33546c;
}
@media (max-width: 480px) {
  .solicita .titles h2 {
    font-size: 6vw;
  }
}
.solicita .titles h3 {
  font-weight: 600;
  color: #ffb94b;
  letter-spacing: 2px;
}
.solicita .items figure {
  text-align: center;
}
.solicita .items figure img {
  margin: 0 auto;
  display: block;
  height: 250px;
}
.solicita .items p {
  color: #33546c;
  text-align: center;
  padding: 0 8%;
  font-family: "Lato", sans-serif;
}

header {
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  background: white;
  box-shadow: 0 1px 1px rgba(128, 128, 128, 0.42);
  display: block;
}
@media (max-width: 480px) {
  header {
    background: #33546c;
    box-shadow: none;
  }
}
@media (max-width: 480px) {
  header .header-logo {
    position: relative;
  }
  header .header-logo .open-menu {
    position: absolute;
    right: 7%;
    top: 50%;
    transform: translateY(-50%);
    color: black;
  }
}
@media (max-width: 480px) and (max-width: 480px) {
  header .header-logo .open-menu {
    color: white;
  }
}
header .header-logo .logo {
  display: flex;
  align-items: center;
  height: 80px;
}
header .header-logo .logo img {
  height: 70%;
  margin-left: 5px;
}
header nav {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.3s ease-in;
}
@media (max-width: 480px) {
  header nav {
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: -200%;
    background: white;
    z-index: 999;
  }
  header nav.active {
    top: 0;
  }
}
header nav ul {
  width: 100%;
  display: flex;
  justify-items: flex-end;
}
@media (max-width: 480px) {
  header nav ul {
    width: 100%;
    flex-direction: column;
  }
}
header nav ul li {
  flex: 1;
  text-align: center;
  padding: 10px;
  position: relative;
}
@media (max-width: 480px) {
  header nav ul li {
    padding: 0;
  }
}
header nav ul li.visible-xs {
  text-align: center;
}
header nav ul li.visible-xs img {
  display: block;
  margin: 0 auto;
  max-height: 70px;
}
header nav ul li a {
  min-height: 56px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Lato", sans-serif;
  color: #33546c;
  font-size: 1.2rem;
  transition: all 0.3s ease-in;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  header nav ul li a {
    display: flex;
    padding: 10px;
    width: 100%;
    height: 100%;
  }
}
header nav ul li a:hover {
  background: rgba(128, 128, 128, 0.36);
  text-decoration: none;
  color: black;
}
header nav .has-submenu:hover .submenu {
  display: flex;
}
header nav .has-submenu .submenu {
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  background: white;
  z-index: 1300;
  flex-direction: column;
}
header nav .has-submenu .submenu li {
  padding: 0;
}

@media (max-width: 480px) {
  .nav-home {
    background: transparent;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1500;
    height: 60px;
  }
  .nav-home.active {
    background: rgba(0, 0, 0, 0.5);
  }
  .nav-home .logo {
    display: flex;
    align-items: center;
    height: 40px;
  }
}
@media (max-width: 480px) and (max-width: 480px) {
  .nav-home .logo {
    justify-content: start;
    height: 50px !important;
    margin-top: 5px;
  }
}
@media (max-width: 480px) {
  .nav-home .logo img {
    height: 70%;
    margin-left: 5px;
  }
}
footer {
  background: #33546c;
  display: block;
}
footer .row .logo, footer .row .redes-footer {
  height: 140px;
  display: flex;
  align-items: center;
}
footer .row .logo {
  justify-content: flex-start;
}
footer .row .logo img {
  max-width: 100%;
  height: 40%;
}
@media (max-width: 480px) {
  footer .row .logo img {
    height: auto;
  }
}
footer .row .redes-footer {
  justify-content: flex-end;
}
footer .row .redes-footer a {
  font-size: 3rem;
  padding-left: 10px;
  color: white;
}
@media (max-width: 480px) {
  footer .row .redes-footer a {
    font-size: 1.9rem;
  }
}

section[data-section=previa] {
  height: 100vh;
  background-size: cover;
  background-position: center center;
}
section[data-section=previa] .text {
  position: fixed;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
@media (max-width: 480px) {
  section[data-section=previa] .text {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
section[data-section=previa] .text h2, section[data-section=previa] .text h3 {
  text-shadow: 1px 1px 2px black;
  text-transform: uppercase;
  color: white;
  font-family: "Lato", sans-serif;
}
section[data-section=previa] .text h2 {
  font-size: 38px;
  font-weight: 700;
}
section[data-section=previa] .text h3 {
  letter-spacing: 1px;
  margin-top: 4px;
  font-weight: 600;
}
section[data-section=previa] .text a {
  border: none;
  background: white;
  box-shadow: 1px 1px 2px black;
  margin-top: 10px;
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
}
section[data-section=previa] .contacto {
  position: fixed;
  right: -150%;
  top: 57%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 1.5%;
  transition: all 0.9s;
}
@media (max-width: 480px) {
  section[data-section=previa] .contacto {
    padding: 6%;
  }
}
section[data-section=previa] .contacto.active {
  right: 0;
}
@media (max-width: 480px) {
  section[data-section=previa] .contacto.active {
    background: rgba(0, 0, 0, 0.9);
  }
}
section[data-section=previa] .contacto .contacto-link-close {
  position: absolute;
  left: -3%;
  top: -2%;
  padding: 5px 10px;
  color: black;
  transition: all 0.3s ease-in-out;
  background: white;
  border-radius: 50px;
}
@media (max-width: 480px) {
  section[data-section=previa] .contacto .contacto-link-close {
    left: 50%;
    transform: translateX(-50%);
  }
}
section[data-section=previa] .contacto .contacto-link-close:hover {
  background: black;
  color: white;
}
section[data-section=previa] .contacto form label {
  font-family: "Lato", sans-serif;
  color: white;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}
section[data-section=previa] .contacto form input, section[data-section=previa] .contacto form textarea {
  width: 100%;
}
section[data-section=previa] .contacto form textarea {
  height: 150px;
}
section[data-section=previa] .contacto form button {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
}

section[data-section=sobre] .sobre-text .image {
  background-size: cover;
  background-position: center center;
}
section[data-section=sobre] .sobre-text .text {
  font-family: "Lato", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-direction: column;
  color: #33546c;
}
section[data-section=sobre] .sobre-text .text .li-space li {
  padding-bottom: 12px;
}
section[data-section=sobre] .sobre-text .text h2 {
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 2.4rem;
}
section[data-section=sobre] .sobre-text .text h2 span {
  display: block;
  margin-top: 5px;
  text-transform: uppercase;
  font-size: 1.2rem;
}
section[data-section=sobre] .sobre-text .text h3 {
  margin-top: 10px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.8rem;
}
section[data-section=sobre] .sobre-text .text p {
  text-align: justify;
  margin-bottom: 20px;
}
section[data-section=sobre] .sobre-text .text > div {
  padding: 10%;
}
@media (max-width: 480px) {
  section[data-section=sobre] .sobre-text .text > div {
    padding: 35px 5%;
  }
}
section[data-section=sobre] .sobre-text .text li {
  padding-bottom: 2px;
}

section[data-section=contacto] {
  display: block;
}
section[data-section=contacto] form .form-contol {
  border-radius: 0 !important;
}
section[data-section=contacto] form textarea {
  height: 128px;
}
section[data-section=contacto] h3 {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}
@media (max-width: 480px) {
  section[data-section=contacto] .vias-container {
    margin-top: 20px;
  }
}
section[data-section=contacto] .vias-container ul.vias li {
  font-size: 1.7rem;
}
section[data-section=contacto] .mapa {
  margin-top: 10px;
  padding: 10px;
  background: white;
  box-shadow: 0 0 2px grey;
}

@media (max-width: 480px) {
  section[data-section=home] {
    background: #33546c;
  }
}
section[data-section=home] .solicita-boton {
  padding: 20px 0;
  display: block;
}
section[data-section=home] .solicita-boton .btn {
  width: 30%;
  padding: 15px;
}
@media (max-width: 480px) {
  section[data-section=home] .solicita-boton .btn {
    width: 80%;
    font-size: 11px;
    margin-bottom: 10px;
  }
}
section[data-section=home] .people-container {
  text-align: center;
  transform: translateY(-54%);
  margin: 0 auto;
  height: 330px;
  z-index: 100 !important;
}
@media (max-width: 480px) {
  section[data-section=home] .people-container {
    height: 60px;
    transform: unset;
    position: absolute;
    left: 0;
    top: 30%;
  }
}
section[data-section=home] .people-container .titles {
  display: block;
  padding-bottom: 25px;
}
section[data-section=home] .people-container .titles h2, section[data-section=home] .people-container .titles h3 {
  text-transform: uppercase;
  color: white;
}
@media (max-width: 480px) {
  section[data-section=home] .people-container .titles h2 {
    font-size: 5vw;
  }
}
section[data-section=home] .people-container .titles h3 {
  font-weight: 300;
}
@media (max-width: 480px) {
  section[data-section=home] .people-container .titles h3 {
    font-size: 4.5vw;
  }
}
section[data-section=home] .people-container-mobile .slick-list {
  height: 490px;
  margin-bottom: 0;
  padding-bottom: 25px;
}
section[data-section=home] .people-container-mobile.slick-slider {
  margin-bottom: -1px !important;
  padding-bottom: 15px;
  border: none;
}

section[data-section=articulos] .item-art {
  padding: 20px;
  background: white;
  box-shadow: 0 1px 2px grey;
  margin-bottom: 25px;
  min-height: 390px;
}
section[data-section=articulos] .item-art figure {
  text-align: center;
}
section[data-section=articulos] .item-art figure img {
  display: block;
  margin: 0 auto;
  max-height: 208px;
}
section[data-section=articulos] .item-art .title {
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  margin: 10px 0;
}
section[data-section=articulos] .item-art p {
  font-family: "Lato", sans-serif;
  font-size: 1.4rem;
  text-align: justify;
}

section[data-section=pagar] .promos {
  margin-top: 10px;
}
section[data-section=pagar] .promos h3, section[data-section=pagar] .promos h4 {
  text-transform: uppercase;
  color: #33546c;
  font-size: 1.5rem;
  margin-bottom: 5px;
}
section[data-section=pagar] .data {
  padding-bottom: 10px;
}
section[data-section=pagar] .data li {
  font-family: "Lato", sans-serif;
}
section[data-section=pagar] .data li b {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
}

section[data-section=detail] figure {
  padding: 20px 0;
}

section[data-section=turnos-presenciales] figure {
  display: block;
  margin: 60px 0;
}
section[data-section=turnos-presenciales] h2 {
  display: block;
  margin: 80px 0;
  font-weight: 400;
  font-family: "Lato", sans-serif;
}
section[data-section=turnos-presenciales] h2, section[data-section=turnos-presenciales] h3, section[data-section=turnos-presenciales] h4 {
  font-family: "Lato", sans-serif;
  color: #33546c;
}
section[data-section=turnos-presenciales] h3.sub {
  background: #33546c;
  color: white;
  padding: 10px 20px 10px 15px;
  display: inline-block;
  margin-bottom: 5px;
  border-radius: 0 10px 0 0;
}
section[data-section=turnos-presenciales] p {
  margin: 0;
  font-family: "Lato", sans-serif;
  padding: 0;
}
section[data-section=turnos-presenciales] .items {
  padding: 60px 0;
}
section[data-section=turnos-presenciales] .items .item {
  text-align: center;
}
@media (max-width: 480px) {
  section[data-section=turnos-presenciales] .items .item {
    margin-bottom: 20px;
  }
}
section[data-section=turnos-presenciales] .items .item img {
  margin-bottom: 10px;
}
section[data-section=turnos-presenciales] .items .item h3 {
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 5px;
  font-size: 30px;
}
section[data-section=turnos-presenciales] .items .item p {
  color: #33546c;
  font-size: 22px;
}
section[data-section=turnos-presenciales] .primera-consulta p, section[data-section=turnos-presenciales] .control p {
  font-size: 23px;
  font-weight: 400;
}
section[data-section=turnos-presenciales] .primera-consulta h4, section[data-section=turnos-presenciales] .control h4 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 800;
  margin-top: 10px;
}
section[data-section=turnos-presenciales] .team {
  margin-top: 30px;
  background: #33546c;
}