footer {
  //padding: 40px 0;
  background: $gris;
  display: block;

  .row {
    .logo, .redes-footer {
      height: 140px;
      display: flex;
      align-items: center;
    }

    .logo {
      justify-content: flex-start;

      img {
        max-width: 100%;
        height: 40%;

        @include mobile {
          height: auto;
        }
      }
    }

    .redes-footer {
      justify-content: flex-end;

      a {
        font-size: 3rem;
        padding-left: 10px;
        color: white;
        @include mobile {
          font-size: 1.9rem;
        }
      }
    }

  }
}